<template>
    <div class="d-flex gap-3 w-100 picker-wrapper">
        <v-text-field type="date" v-model="birthDate" :max="new Date().toISOString().split('T')[0]"
            class="date-picker flex-grow-1" />
        <button class="btn btn-success save-button flex-grow-1" @click="saveBirthday">{{ $t('global.save') }}</button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueService from '@/services/VueService.js';

export default {
    props: ['closePicker'],
    data() {
        return {
            birthDateLocal: null,
        }
    },
    computed: {
        ...mapGetters(['clientObj']),
        birthDate: {
            get() {
                if (this.birthDateLocal) {
                    return this.birthDateLocal
                }

                if (this.clientObj?.birthDate) {
                    return new Date(this.clientObj.birthDate).toISOString().split('T')[0];
                }

                return null;
            },
            set(newDate) {
                this.birthDateLocal = newDate
            }
        }
    },
    methods: {
        ...mapActions(['setBirthday']),
        async saveBirthday() {
            try {
                if (this.birthDateLocal === null) {
                    throw new Error('Birthday is required');
                }
                await this.setBirthday(this.birthDateLocal);
                this.closePicker();
            } catch (e) {
                VueService.showToastMessage({ message: this.$t('global.birthdayNotValid'), type: 'error' });
            }
        }
    }
}
</script>

<style>
.date-picker {
    width: 100%;
}

.theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
    color: #fff;
}

.v-application .primary--text {
    color: #fff !important;
}

.v-input {
    background-color: transparent !important;
}

.v-text-field {
    padding-top: 0;
    margin-top: 0;
}

.v-messages,
.v-text-field__details {
    min-height: 0;
}

.v-input__slot {
    margin-bottom: 0;
}

.picker-wrapper {
    flex-direction: column;
}

.v-text-field__slot {
    color-scheme: dark;
}

@media (min-width: 576px) {
    .date-picker {
        max-width: 290px;
    }

    .picker-wrapper {
        flex-direction: row;
    }

    .save-button {
        max-width: 100px;
    }
}
</style>